import React from "react";
import { populate } from "../1.resources/2.js";
import "../1.resources/1.css/index.css";
class Index extends React.Component {
    componentDidMount() {

        populate();
    }

    render() {

        return (
            <div id="main" >
            </div>
        );
    }
}

export default Index;
