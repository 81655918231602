const { ethers } = require('ethers');


const getProvider = () => {
    let providerArray = JSON.parse(process.env.REACT_APP_CHAINSTACK_ARRAY).links;
    const random = Math.floor(Math.random() * providerArray.length);
    let providerUrl = providerArray[random];
    let provider = new ethers.providers.JsonRpcProvider(providerUrl);
    return provider
}

module.exports = getProvider;