import React from "react";
import Index from "./3.views";
class App extends React.Component {
    componentDidMount() {
    }

    render() {
        return (
            <Index />
        );
    }
}

export default App;